<template>
    <!-- WARNING this file is generated edits made will be lost on next generation -->
    <div>
        <NavBar></NavBar>
        <div class="container-fluid">
            <b-row>
            <!-- Sidebar -->
            <TOCChapter chapter-id="Chap9ControlStatements"></TOCChapter>
				<b-col ></b-col>
                <!-- Main Content -->
                <b-col role="main" md="6" >
					<ChapterHeading chapter-title="Chapter 9: Control Statements" image-name="control-statements.jpg" image-alt="Control Statements"></ChapterHeading>
                    <!-- Contents BEGIN -->
                    <div id="what-will-you-learn-in-this-chapter" class="anchor"></div>
<h1 data-number="1"><span class="header-section-number">1</span> What will you learn in this chapter? <a href="#what-will-you-learn-in-this-chapter"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li>Understand why we need control statements</li>
</ul>
<div id="technical-concepts-covered" class="anchor"></div>
<h1 data-number="2"><span class="header-section-number">2</span> Technical concepts covered <a href="#technical-concepts-covered"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>Control statements</p>
<ul>
<li><p>if / else</p></li>
<li><p>if / else if / else</p></li>
<li><p>switch statement</p></li>
<li><p>For a statement with a single condition</p></li>
<li><p>For statement with for clause</p></li>
</ul></li>
<li><p>Flow of execution</p></li>
<li><p>Boolean expressions</p></li>
<li><p>Comparison operators</p></li>
<li><p>Loop</p></li>
</ul>
<div id="a-world-without-control-statements" class="anchor"></div>
<h1 data-number="3"><span class="header-section-number">3</span> A world without control statements <a href="#a-world-without-control-statements"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>In the previous chapter, we have seen that :</p>
<ul>
<li><p>we can define variables with an associated type</p></li>
<li><p>we can assign to those variables a value</p></li>
<li><p>we can also create constants (that can be typed or untyped)</p></li>
</ul>
<p>With only this knowledge, we can build interesting programs. However, we will not be able to implement complex logic. Let’s take an example. Here is a requirement for our hotel project :</p>
<ul>
<li><p>When a hotel employee launch the management application, he can see the the screen A when no rooms are available (see figure <a href="#fig:Wireframes-conditional" data-reference-type="ref" data-reference="fig:Wireframes-conditional">1</a>).</p></li>
<li><p>When at least one room is available, he will see screen B (In wireframe B, three rooms are available). The XXX have to be replaced with the name of the hotel.</p></li>
</ul>
<figure>
<b-img :src="require('@/assets/images/conditional_screen.png')" alt="[fig:Wireframes-conditional]Wireframes: the application management hotel"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true"><span id="fig:Wireframes-conditional" label="fig:Wireframes-conditional">[fig:Wireframes-conditional]</span>Wireframes: the application management hotel</figcaption>
</figure>
<div id="skeleton-of-the-application" class="anchor"></div>
<h2 data-number="3.1"><span class="header-section-number">3.1</span> Skeleton of the application <a href="#skeleton-of-the-application"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Let’s start building the program. We will begin with the skeleton of the application :</p>
<pre v-highlightjs><code class="go" v-pre >package main

func main() {

}</code></pre>
<p>On the second line, you can find the package declaration. We are inside package main. And then we have the main function. (we will see later the notions of function and package).</p>
<p>Let’s build our program :</p>
<pre v-highlightjs><code class="go" v-pre >$ go build -o skeleton conditionals/firstApplication/withoutConditionals/1_skeleton/main.go</code></pre>
<p>We use the go build command.<span v-highlightjs><code class="go" v-pre style="display: inline">-o skeleton</code></span> means that we will name our binary <strong>skeleton</strong>. We then provide the path of our go file. Here is a summary of the command :</p>
<pre v-highlightjs><code class="go" v-pre >$ go build -o BINARY_NAME PATH/TO/MAIN.GO</code></pre>
<div id="headers" class="anchor"></div>
<h2 data-number="3.2"><span class="header-section-number">3.2</span> Headers <a href="#headers"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>When we execute the program, nothing happens.</p>
<p>Let’s add some statements to our program :</p>
<pre v-highlightjs><code class="go" v-pre >// control-statements/first-statements/main.go
package main

import &quot;fmt&quot;

func main() {
    hotelName := &quot;The Gopher Hotel&quot;
    fmt.Println(&quot;Hotel &quot; + hotelName)
}</code></pre>
<p>Here we define a variable <span v-highlightjs><code class="go" v-pre style="display: inline">hotelName</code></span><strong>.</strong>We initialize it with a string containing our hotel name. Then we use <span v-highlightjs><code class="go" v-pre style="display: inline">fmt.Println</code></span> to print to the screen the string: <span v-highlightjs><code class="go" v-pre style="display: inline">"Hotel"</code></span> concatenated with the string contained in the variable <span v-highlightjs><code class="go" v-pre style="display: inline">hotelName</code></span><strong>.</strong> Let’s compile and run our program :</p>
<pre v-highlightjs><code class="go" v-pre >$ go build -o v1 conditionals/firstApplication/withoutConditionals/2_header/main.go
$ ./v1
Hotel The Gopher Hotel</code></pre>
<div id="fixed-values" class="anchor"></div>
<h2 data-number="3.3"><span class="header-section-number">3.3</span> Fixed values <a href="#fixed-values"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>The next step is to display “No available rooms now” or “N rooms available” depending on the number of rooms available. The number of free is variable. It makes sense to create a variable named <span v-highlightjs><code class="go" v-pre style="display: inline">roomsAvailable</code></span>. This variable is an unsigned integer (3, 4, 112, 0).</p>
<p>How do we get the actual value of <span v-highlightjs><code class="go" v-pre style="display: inline">roomsAvailable</code></span>? We need to get the total number of rooms (A) and the number of rooms occupied (B), the value of our variable is <span class="math inline">A-B</span>.</p>
<pre v-highlightjs><code class="go" v-pre >// control-statements/first-app-without-conditionals/main.go
package main

import &quot;fmt&quot;

func main() {
    hotelName := &quot;The Gopher Hotel&quot;
    fmt.Println(&quot;Hotel &quot; + hotelName)
    var roomsAvailable uint8
    var rooms, roomsOccupied uint8 = 100, 10
    roomsAvailable = rooms - roomsOccupied //*\label{cond1diff}
    fmt.Println(roomsAvailable, &quot;rooms available&quot;)
}</code></pre>
<p>In the previous listing, we added the declaration of 3 variables (all with the uint8 type)</p>
<ul>
<li><p><strong><span v-highlightjs><code class="go" v-pre style="display: inline">roomsAvailable</code></span></strong> : the number of rooms that can be rent</p></li>
<li><p><strong><span v-highlightjs><code class="go" v-pre style="display: inline">rooms</code></span></strong> : the total number of rooms in the hotel</p></li>
<li><p><strong><span v-highlightjs><code class="go" v-pre style="display: inline">roomsOccupied</code></span></strong> : the number of rooms currently occupied</p></li>
</ul>
<p>Note that <strong><span v-highlightjs><code class="go" v-pre style="display: inline">roomsAvailable</code></span></strong> was declared and not initialized whereas <strong><span v-highlightjs><code class="go" v-pre style="display: inline">rooms</code></span></strong> and <strong><span v-highlightjs><code class="go" v-pre style="display: inline">roomsOccupied</code></span></strong> were declared and initialized at the same time.</p>
<p>Then we assign to <span v-highlightjs><code class="go" v-pre style="display: inline">roomsAvailable</code></span> the value of <span v-highlightjs><code class="go" v-pre style="display: inline">rooms - roomsOccupied</code></span> on line <a href="#cond1diff" data-reference-type="ref" data-reference="cond1diff">[cond1diff]</a>.</p>
<div id="random-values" class="anchor"></div>
<h2 data-number="3.4"><span class="header-section-number">3.4</span> Random values <a href="#random-values"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>In the previous section, we have written directly into the program’s values of rooms and roomsOccupied. We will fetch those values from a database or an API call in the real-life. To simulate this (before we learn it later), we will get a random number. To do that, Go has a handy function :</p>
<pre v-highlightjs><code class="go" v-pre >rand.Intn(100)</code></pre>
<p>The function <span v-highlightjs><code class="go" v-pre style="display: inline">Intn</code></span> from package <span v-highlightjs><code class="go" v-pre style="display: inline">rand</code></span> will generate a non-negative random number between 0 and the number provided in parameters. For instance, if we call <span v-highlightjs><code class="go" v-pre style="display: inline">rand.Intn(100)</code></span> We will generate a random number between 0 and 100.</p>
<pre v-highlightjs><code class="go" v-pre >// control-statements/random/main.go
package main

import (
    &quot;fmt&quot;
    &quot;math/rand&quot;
)

func main() {
    hotelName := &quot;The Gopher Hotel&quot;
    fmt.Println(&quot;Hotel &quot; + hotelName)
    var roomsAvailable int
    var rooms, roomsOccupied int = 100, rand.Intn(100)
    roomsAvailable = rooms - roomsOccupied
    fmt.Println(roomsAvailable, &quot;rooms available&quot;)
}</code></pre>
<p>We begin by adding the import of the <span v-highlightjs><code class="go" v-pre style="display: inline">math/rand</code></span> package on line <a href="#cond4mathrand" data-reference-type="ref" data-reference="cond4mathrand">[cond4mathrand]</a>. This is mandatory to be able to use <span v-highlightjs><code class="go" v-pre style="display: inline">rand.Intn</code></span>.</p>
<p>We need to change the type of our three variables because <span v-highlightjs><code class="go" v-pre style="display: inline">rand.Intn</code></span> will generate an <span v-highlightjs><code class="go" v-pre style="display: inline">int</code></span> and not an <span v-highlightjs><code class="go" v-pre style="display: inline">uint8</code></span>.</p>
<p>Then we assign to <span v-highlightjs><code class="go" v-pre style="display: inline">roomsOccupied</code></span> the value of <span v-highlightjs><code class="go" v-pre style="display: inline">rand.Intn(100)</code></span>. In other words, the value of <span v-highlightjs><code class="go" v-pre style="display: inline">roomsOccupied</code></span> will vary between <span class="math inline">0</span> and <span class="math inline">100</span>.</p>
<p>Let’s build and run our program :</p>
<pre v-highlightjs><code class="go" v-pre >$ go build -o random conditionals/firstApplication/withoutConditionals/4_randomValues/main.go    $ ./random
Hotel The Gopher Hotel
19 rooms available</code></pre>
<p>Run it another time :</p>
<pre v-highlightjs><code class="go" v-pre >$ ./random
Hotel The Gopher Hotel
19 rooms available</code></pre>
<p>The result is strange. I’m sure you expected another value than 19! It seems that the random number is fixed. (you can try to execute it 3,4, 5 times. You will still get the same value). To work properly, the random library needs to be <strong>seeded</strong>. The seed is a number that is used to initialize a pseudo-random generator.</p>
<div id="random-values-seeded" class="anchor"></div>
<h2 data-number="3.5"><span class="header-section-number">3.5</span> Random values seeded <a href="#random-values-seeded"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<pre v-highlightjs><code class="go" v-pre >// control-statements/random-seeded/main.go
package main

import (
    &quot;fmt&quot;
    &quot;math/rand&quot;
    &quot;time&quot;
)

func main() {
    hotelName := &quot;The Gopher Hotel&quot;
    fmt.Println(&quot;Hotel &quot; + hotelName)
    var roomsAvailable int
    rand.Seed(time.Now().UTC().UnixNano())
    var rooms, roomsOccupied int = 100, rand.Intn(100)
    roomsAvailable = rooms - roomsOccupied
    fmt.Println(roomsAvailable, &quot;rooms available&quot;)
}</code></pre>
<p>We seed our random number generator with <span v-highlightjs><code class="go" v-pre style="display: inline">time.Now().UTC().UnixNano()</code></span> which is the number of elapsed nanoseconds between the 1st January 1970 at midnight in UTC and now. Note that this point in time is also called the UNIX Epoch.</p>
<p>Let’s build and run our program :</p>
<pre v-highlightjs><code class="go" v-pre >$ go build -o random2 conditionals/firstApplication/withoutConditionals/5_randomValuesSeeded/main.go
$ ./random2
Hotel The Gopher Hotel
Seven rooms available</code></pre>
<p>Run it another time :</p>
<pre v-highlightjs><code class="go" v-pre >$ ./random2
Hotel The Gopher Hotel
36 rooms available</code></pre>
<p>It works!</p>
<div id="when-roomsavailable-is-equal-to-0-the-specification-is-not-respected" class="anchor"></div>
<h2 data-number="3.6"><span class="header-section-number">3.6</span> When roomsAvailable is equal to 0, the specification is not respected <a href="#when-roomsavailable-is-equal-to-0-the-specification-is-not-respected"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>When the random value is equal to 100, then the variable <span v-highlightjs><code class="go" v-pre style="display: inline">roomsAvailable</code></span> will be equal to 0 (<span class="math inline">100-100)</span>. The program will output the following :</p>
<pre v-highlightjs><code class="go" v-pre >$ ./random2
Hotel The Gopher Hotel
0 rooms available</code></pre>
<p>This is not correct. The specification is not respected. It should display :</p>
<pre v-highlightjs><code class="go" v-pre >Hotel The Gopher Hotel
No available rooms now</code></pre>
<p>With our current knowledge, we cannot meet the specification.</p>
<p>We need to have a tool that allows us to print the required text if <span v-highlightjs><code class="go" v-pre style="display: inline">roomsAvailable</code></span> is equal to zero. Concretely our program needs to have two outputs possible. When the hotel is full, another one when the hotel is not. The program has a condition and two possible outcomes depending on the condition.</p>
<figure>
<b-img :src="require('@/assets/images/conditional.png')" alt="The program has two branches depending on the value of roomsAvailable"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">The program has two branches depending on the value of <strong>roomsAvailable</strong></figcaption>
</figure>
<div id="boolean-expressions" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="4"><span class="header-section-number">4</span> Boolean Expressions <a href="#boolean-expressions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="definition" class="anchor"></div>
<h2 data-number="4.1"><span class="header-section-number">4.1</span> Definition <a href="#definition"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>A boolean expression is a statement that will be evaluated at runtime and that should return either true or false. In other words, a boolean expression is a question that can be answered by true or false. Here are some examples :</p>
<ul>
<li><p>Is the room available?</p></li>
<li><p>Has the customer paid his bill?</p></li>
<li><p>Is the hotel full?</p></li>
<li><p>Did we send a reservation email to the customer?</p></li>
</ul>
<p>A boolean expression is evaluated at the program runtime, which means that when we create the program, we are not able to evaluate it.</p>
<p>The result will depend on the value of the data that the program will load. For instance, when we construct our program, we will load from a database the number of rooms available. We will put that number into a variable, and we will compare it to 0. If the rooms available is equal to 0, then the answer to “Is the hotel full?” is true.</p>
<div id="boolean-expressions-1" class="anchor"></div>
<h2 data-number="4.2"><span class="header-section-number">4.2</span> Boolean expressions <a href="#boolean-expressions-1"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Here are some examples of boolean expressions :</p>
<pre v-highlightjs><code class="go" v-pre >// control-statements/boolean-expressions/main.go
package main

import (
    &quot;fmt&quot;
    &quot;math/rand&quot;
    &quot;time&quot;
)

func main() {
    rand.Seed(time.Now().UTC().UnixNano())
    var rooms, roomsOccupied int = 100, rand.Intn(100)

    fmt.Println(&quot;rooms :&quot;, rooms, &quot; roomsOccupied :&quot;, roomsOccupied)

    // Example 1: is there more rooms than roomsOccupied
    fmt.Println(&quot;boolean expression : &#39;rooms &gt; roomsOccupied&#39; is equal to :&quot;)
    fmt.Println(rooms &gt; roomsOccupied) //*\label{condExpBool1}

    // Example 2: is there more roomsOccupied than rooms
    fmt.Println(&quot;boolean expression : &#39;roomsOccupied &gt; rooms&#39; is equal to :&quot;)
    fmt.Println(roomsOccupied &gt; rooms) //*\label{condExpBool2}

    // Example 3: is roomsOccupied equal to rooms
    fmt.Println(&quot;boolean expression : &#39;roomsOccupied == rooms&#39; is equal to :&quot;)
    fmt.Println(roomsOccupied == rooms) //*\label{condExpBool3}
}</code></pre>
<p>In this program, we begin by calling the function <span v-highlightjs><code class="go" v-pre style="display: inline">Seed</code></span> from the package <span v-highlightjs><code class="go" v-pre style="display: inline">math/randed</code></span>. This function will “seed” The pseudo-random generator. When seeded, the generator will output different numbers for each run.</p>
<p>Then we define two <span v-highlightjs><code class="go" v-pre style="display: inline">int</code></span> variables <span v-highlightjs><code class="go" v-pre style="display: inline">rooms, roomsOccupied</code></span> that are initialized with respectively 100 and the value returned by <span v-highlightjs><code class="go" v-pre style="display: inline">rand.Intn(100)</code></span> (a random int between 0 and 100).</p>
<p>On the line <a href="#condExpBool1" data-reference-type="ref" data-reference="condExpBool1">[condExpBool1]</a> we create a boolean expression : <span v-highlightjs><code class="go" v-pre style="display: inline">rooms &gt; roomsOccupied</code></span>. When the program is executed, it will check if <span v-highlightjs><code class="go" v-pre style="display: inline">rooms</code></span> is greater than <span v-highlightjs><code class="go" v-pre style="display: inline">roomsOccupied</code></span>. If it’s the case, the value of <span v-highlightjs><code class="go" v-pre style="display: inline">rooms &gt; roomsOccupied</code></span> will be <span v-highlightjs><code class="go" v-pre style="display: inline">true</code></span>. If not, the value will be <span v-highlightjs><code class="go" v-pre style="display: inline">false</code></span>.</p>
<p>On the line <a href="#condExpBool2" data-reference-type="ref" data-reference="condExpBool2">[condExpBool2]</a> we create a boolean expression : <span v-highlightjs><code class="go" v-pre style="display: inline">roomsOccupied &gt; rooms</code></span>. When the program is executed, it will check if <span v-highlightjs><code class="go" v-pre style="display: inline">roomsOccupied</code></span> is greater than <span v-highlightjs><code class="go" v-pre style="display: inline">rooms</code></span>. If it’s the case, the value of <span v-highlightjs><code class="go" v-pre style="display: inline">roomsOccupied &gt; rooms</code></span> will be <span v-highlightjs><code class="go" v-pre style="display: inline">true</code></span>. If not, the value will be <span v-highlightjs><code class="go" v-pre style="display: inline">false</code></span>.</p>
<p>In this program, we defined a third boolean expression <a href="#condExpBool3" data-reference-type="ref" data-reference="condExpBool3">[condExpBool3]</a> : <span v-highlightjs><code class="go" v-pre style="display: inline">roomsOccupied == rooms</code></span>. This expression will be <span v-highlightjs><code class="go" v-pre style="display: inline">true</code></span> if the two variable values are equal, if not, it will be equal to <span v-highlightjs><code class="go" v-pre style="display: inline">false</code></span>.</p>
<p>Let’s build and execute the program :</p>
<pre v-highlightjs><code class="go" v-pre >$ go build -o boolExp conditionals/booleanExpression/main.go
$ ./boolExp
rooms : 100  roomsOccupied : 98
boolean expression : &#39;rooms &gt; roomsOccupied&#39; is equal to :
true
boolean expression : &#39;roomsOccupied &gt; rooms&#39; is equal to :
false
boolean expression : &#39;roomsOccupied == rooms&#39; is equal to :
false</code></pre>
<p>In this execution :</p>
<ul>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">rooms</code></span> = 100</p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">roomsOccupied</code></span> = 98</p></li>
</ul>
<p>Our boolean expressions will be :</p>
<ul>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">rooms &gt; roomsOccupied</code></span><span class="math inline">(100&gt;98)</span> = <span v-highlightjs><code class="go" v-pre style="display: inline">true</code></span></p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">roomsOccupied &gt; rooms</code></span><span class="math inline">(98&gt;100)</span> = <span v-highlightjs><code class="go" v-pre style="display: inline">false</code></span></p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">roomsOccupied == rooms</code></span><span class="math inline">(98==100)</span> = <span v-highlightjs><code class="go" v-pre style="display: inline">false</code></span></p></li>
</ul>
<p>In those three boolean expressions, we have used the symbols <span v-highlightjs><code class="go" v-pre style="display: inline">&gt;, ==</code></span> those symbols are comparison operators. In the next section, we will list them.</p>
<div id="comparison-operators" class="anchor"></div>
<h1 data-number="5"><span class="header-section-number">5</span> Comparison Operators <a href="#comparison-operators"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>To compare the value of two values, you can use six operators :</p>
<table>
<caption>Comparison Operators</caption>
<thead>
<tr class="header">
<th style="text-align: center;">Operator</th>
<th style="text-align: center;">Signification</th>
</tr>
</thead>
<tbody>
<tr class="odd">
<td style="text-align: center;">==</td>
<td style="text-align: center;">equal</td>
</tr>
<tr class="even">
<td style="text-align: center;">!=</td>
<td style="text-align: center;">not equal</td>
</tr>
<tr class="odd">
<td style="text-align: center;">&gt;</td>
<td style="text-align: center;">greater</td>
</tr>
<tr class="even">
<td style="text-align: center;">&gt;=</td>
<td style="text-align: center;">greater or equal</td>
</tr>
<tr class="odd">
<td style="text-align: center;">&lt;</td>
<td style="text-align: center;">less</td>
</tr>
<tr class="even">
<td style="text-align: center;">&lt;=</td>
<td style="text-align: center;">less or equal</td>
</tr>
</tbody>
</table>
<p>Here is a program that uses all six operators :</p>
<pre v-highlightjs><code class="go" v-pre >// control-statements/comparison-operators/main.go
package main

import &quot;fmt&quot;

func main() {
    a := 21
    b := 42

    fmt.Println(a == b) // false
    fmt.Println(a == a) // true
    fmt.Println(a != b) // true
    fmt.Println(a &gt; b)  // false
    fmt.Println(a &lt; b)  // true
    fmt.Println(a &lt;= b) // true
    fmt.Println(a &lt;= a) // true
    fmt.Println(a &gt;= a) // true
}</code></pre>
<p>The comparison operator used to test equality is <span v-highlightjs><code class="go" v-pre style="display: inline">==</code></span> and not <span v-highlightjs><code class="go" v-pre style="display: inline">=</code></span>. The last is the symbol used for <strong>assignment</strong> and not for <strong>comparison</strong>. Replacing <span v-highlightjs><code class="go" v-pre style="display: inline">==</code></span> with <span v-highlightjs><code class="go" v-pre style="display: inline">=</code></span> is a common mistake, so watch it.</p>
<div id="application-boolean-expressions-comparison-operators" class="anchor"></div>
<h1 data-number="6"><span class="header-section-number">6</span> Application : boolean expressions &amp; comparison operators <a href="#application-boolean-expressions-comparison-operators"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="requirements" class="anchor"></div>
<h2 data-number="6.1"><span class="header-section-number">6.1</span> Requirements <a href="#requirements"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Write a program that defines two <span v-highlightjs><code class="go" v-pre style="display: inline">int</code></span> variables <span v-highlightjs><code class="go" v-pre style="display: inline">ageJohn</code></span>, <span v-highlightjs><code class="go" v-pre style="display: inline">agePaul</code></span>. Those are randomly initialized. Those two variables may vary from 0 to 110 (excluded, consequently the maximum number is 109). <span v-highlightjs><code class="go" v-pre style="display: inline">ageJohn</code></span> represents the age of John, <span v-highlightjs><code class="go" v-pre style="display: inline">agePaul</code></span> the age of Paul. For example, the program outputs the following lines:</p>
<ul>
<li><p>John is <strong>42</strong> years old</p></li>
<li><p>Paul is <strong>1</strong> years old</p></li>
<li><p>It is <strong>true</strong> that John is older than Paul</p></li>
<li><p>It is <strong>false</strong> that John and Paul have the same age</p></li>
</ul>
<div id="solution" class="anchor"></div>
<h2 data-number="6.2"><span class="header-section-number">6.2</span> Solution <a href="#solution"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<pre v-highlightjs><code class="go" v-pre >// control-statements/application-solution/main.go
package main

import (
    &quot;fmt&quot;
    &quot;math/rand&quot;
    &quot;time&quot;
)

func main() {
    rand.Seed(time.Now().UTC().UnixNano())
    var agePaul, ageJohn int = rand.Intn(110), rand.Intn(110)

    fmt.Println(&quot;John is&quot;, ageJohn, &quot;years old&quot;)
    fmt.Println(&quot;Paul is&quot;, agePaul, &quot;years old&quot;)
    fmt.Println(&quot;It is&quot;, ageJohn &gt; agePaul, &quot;that John is older than Paul&quot;)
    fmt.Println(&quot;It is&quot;, ageJohn == agePaul, &quot;that John and Paul have the same age&quot;)
}</code></pre>
<p>Let’s build and run our program :</p>
<pre v-highlightjs><code class="go" v-pre >$ go build -o app1 conditionals/application1/main.go
$ ./app1
John is 71 years old
Paul is 41 years old
John is indeed older than Paul
It is false that John and Paul have the same age</code></pre>
<p>We can try to run it a second time :</p>
<pre v-highlightjs><code class="go" v-pre >$ ./app1
John is 32 years old
Paul is 58 years old
It is false that John is older than Paul
It is false that John and Paul have the same age</code></pre>
<div id="notes" class="anchor"></div>
<h2 data-number="6.3"><span class="header-section-number">6.3</span> Notes <a href="#notes"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">ageJohn &gt; agePaul</code></span> is a boolean expression. It will be evaluated at runtime. If <span v-highlightjs><code class="go" v-pre style="display: inline">ageJohn</code></span> is greater than <span v-highlightjs><code class="go" v-pre style="display: inline">agePaul</code></span>, then it will be equal to true, false otherwise. The comparison operator used is <span v-highlightjs><code class="go" v-pre style="display: inline">&gt;</code></span>.</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">ageJohn == agePaul</code></span> is also a boolean expression. It will test the equality between the two variables. The comparison operator is <span v-highlightjs><code class="go" v-pre style="display: inline">==</code></span>.</p>
<div id="if-else-statement" class="anchor"></div>
<h1 data-number="7"><span class="header-section-number">7</span> If / else statement <a href="#if-else-statement"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="concept" class="anchor"></div>
<h2 data-number="7.1"><span class="header-section-number">7.1</span> Concept <a href="#concept"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>An if-else statement allows you to execute instruction(s) when the value of a <strong>boolean expression</strong> is evaluated to true. When the value of the boolean expression is false, then another set of instructions are executed by the program. You can see on the figure <a href="#fig:If-else-statement" data-reference-type="ref" data-reference="fig:If-else-statement">2</a> that an if statement generates two possible branches. When the boolean expression is evaluated to true, then the green set of instruction is executed. When the boolean expression is false, the other set of instructions is executed</p>
<figure>
<b-img :src="require('@/assets/images/if_flow_exec.png')" alt="If else statement flow of execution[fig:If-else-statement]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">If else statement flow of execution<span id="fig:If-else-statement" label="fig:If-else-statement">[fig:If-else-statement]</span></figcaption>
</figure>
<div id="syntax" class="anchor"></div>
<h2 data-number="7.2"><span class="header-section-number">7.2</span> Syntax <a href="#syntax"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<figure>
<b-img :src="require('@/assets/images/if_else_syntax.png')" alt="If else syntax with Go[fig:If-else-syntax]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">If else syntax with Go<span id="fig:If-else-syntax" label="fig:If-else-syntax">[fig:If-else-syntax]</span></figcaption>
</figure>
<div id="example" class="anchor"></div>
<h2 data-number="7.3"><span class="header-section-number">7.3</span> Example <a href="#example"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<pre v-highlightjs><code class="go" v-pre >// control-statements/if-else/main.go
package main

import (
    &quot;fmt&quot;
    &quot;math/rand&quot;
    &quot;time&quot;
)

func main() {
    rand.Seed(time.Now().UTC().UnixNano())
    var agePaul, ageJohn int = rand.Intn(110), rand.Intn(110)

    fmt.Println(&quot;John is&quot;, ageJohn, &quot;years old&quot;)
    fmt.Println(&quot;Paul is&quot;, agePaul, &quot;years old&quot;)

    if agePaul &gt; ageJohn {
        fmt.Println(&quot;Paul is older than John&quot;)
    } else {
        fmt.Println(&quot;Paul is younger than John, or both have the same age&quot;)
    }
}</code></pre>
<div id="code-explanation" class="anchor"></div>
<h3 data-number="7.3.1"><span class="header-section-number">7.3.1</span> Code explanation <a href="#code-explanation"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>We begin by seeding our pseudo-random number generator. Then, we define two int variables : <span v-highlightjs><code class="go" v-pre style="display: inline">ageJohn</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">ageJohn</code></span>. We assign the value <span v-highlightjs><code class="go" v-pre style="display: inline">rand.Intn(110)</code></span> to those variables. It represents a pseudo-random number between <span class="math inline">0</span> and <span class="math inline">109</span>.</p>
<p>We print the value of those variables. Then the most interesting part is on the next line. We start an if/else structure :</p>
<ul>
<li><p>The boolean expression is <span v-highlightjs><code class="go" v-pre style="display: inline">agePaul &gt; ageJohn</code></span></p></li>
<li><p>The code that will be executed if this expression is true is <span v-highlightjs><code class="go" v-pre style="display: inline">fmt.Println("Paul is older than John")</code></span></p></li>
<li><p>If the expression is false, this code will be executed : <span v-highlightjs><code class="go" v-pre style="display: inline">fmt.Println("Paul is younger than John, or both have the same age")</code></span></p></li>
</ul>
<div id="code-compilation-and-execution" class="anchor"></div>
<h3 data-number="7.3.2"><span class="header-section-number">7.3.2</span> Code compilation and execution <a href="#code-compilation-and-execution"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>First, we compile the program :</p>
<pre v-highlightjs><code class="go" v-pre >$ go build -o ifelse conditionals/ifElse/syntax/main.go</code></pre>
<p>Now we can execute it :</p>
<pre v-highlightjs><code class="go" v-pre >$ ./ifelse
John is 13 years old
Paul is 69 years old
Paul is older than John</code></pre>
<p>Let’s try to execute it another time :</p>
<pre v-highlightjs><code class="go" v-pre >John is 61 years old
Paul is 58 years old
Paul is younger than John, or both have the same age</code></pre>
<div id="comment" class="anchor"></div>
<h3 data-number="7.3.3"><span class="header-section-number">7.3.3</span> Comment <a href="#comment"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>Our program can have two different output depending on the value of <span v-highlightjs><code class="go" v-pre style="display: inline">agePaul</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">ageJohn</code></span>. With an if-else statement, we can create two branches in the program</p>
<div id="nested-if-else-statements" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="8"><span class="header-section-number">8</span> Nested if / else statements <a href="#nested-if-else-statements"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="concept-1" class="anchor"></div>
<h2 data-number="8.1"><span class="header-section-number">8.1</span> Concept <a href="#concept-1"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>You can nest if/else statement as many times as you wish. It can be useful if you want to create additional sub-branches. To better understand how it will work, you can look at the figure. <a href="#fig:Nested-if/else-statements" data-reference-type="ref" data-reference="fig:Nested-if/else-statements">4</a>. In this example, we have a first boolean expression (<span v-highlightjs><code class="go" v-pre style="display: inline">Boolean expresion 1</code></span>). It will create two possible branches. The first branch (when the expression is true) will execute code 1. In the second branch, we have another if/else construction. It creates two additional branches. <span v-highlightjs><code class="go" v-pre style="display: inline">code 2</code></span> will be executed if <span v-highlightjs><code class="go" v-pre style="display: inline">Boolean expresion 2</code></span> is true (and <span v-highlightjs><code class="go" v-pre style="display: inline">code 3</code></span> if it is false)</p>
<p><b-img :src="require('@/assets/images/nested_conditional.png')" alt="Nested if/else statements[[fig:Nested-if/else-statements]]{#fig:Nested-if/else-statements label=“fig:Nested-if/else-statements”}"  fluid thumbnail class="img-book"></b-img>{#fig:Nested-if/else-statements width=“80%”}</p>
<div id="syntax-1" class="anchor"></div>
<h2 data-number="8.2"><span class="header-section-number">8.2</span> Syntax <a href="#syntax-1"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<figure>
<b-img :src="require('@/assets/images/nested_if_else_syntax.png')" alt="Nested if/else syntax"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Nested if/else syntax</figcaption>
</figure>
<div id="example-1" class="anchor"></div>
<h2 data-number="8.3"><span class="header-section-number">8.3</span> Example <a href="#example-1"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<pre v-highlightjs><code class="go" v-pre >// control-statements/nested-if-else/main.go
package main

import (
    &quot;fmt&quot;
    &quot;math/rand&quot;
    &quot;time&quot;
)

func main() {
    rand.Seed(time.Now().UTC().UnixNano())
    var agePaul, ageJohn int = rand.Intn(110), rand.Intn(110)

    fmt.Println(&quot;John is&quot;, ageJohn, &quot;years old&quot;)
    fmt.Println(&quot;Paul is&quot;, agePaul, &quot;years old&quot;)

    if agePaul &gt; ageJohn {
        fmt.Println(&quot;Paul is older than John&quot;)
    } else {
        // another if/else structure
        if agePaul == ageJohn {
            fmt.Println(&quot;Paul and John have the same age&quot;)
        } else {
            fmt.Println(&quot;Paul is younger than John&quot;)
        }
    }
}</code></pre>
<div id="code-explanation-1" class="anchor"></div>
<h3 data-number="8.3.1"><span class="header-section-number">8.3.1</span> Code explanation <a href="#code-explanation-1"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>Inside the else block, we created another if-else statement :</p>
<pre v-highlightjs><code class="go" v-pre >if agePaul == ageJohn {
   fmt.Println(&quot;Paul and John have the same age&quot;)
} else {
   fmt.Println(&quot;Paul is younger than John&quot;)
}</code></pre>
<div id="code-compilation-and-execution-1" class="anchor"></div>
<h3 data-number="8.3.2"><span class="header-section-number">8.3.2</span> Code compilation and execution <a href="#code-compilation-and-execution-1"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>Here is the command to build the program :</p>
<pre v-highlightjs><code class="go" v-pre >$ go build -o nestedifelse conditionals/ifElse/nested/main.go</code></pre>
<p>Let’s execute it :</p>
<pre v-highlightjs><code class="go" v-pre >./nestedifelse
John is 97 years old
Paul is 73 years old
Paul is younger than John</code></pre>
<div id="comment-1" class="anchor"></div>
<h3 data-number="8.3.3"><span class="header-section-number">8.3.3</span> Comment <a href="#comment-1"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>Even if this type of construct is legal, I do not recommend them. The reason is simple: it’s hard to read and to understand. You can easily avoid it by using other useful constructs. (like a switch, for example).</p>
<div id="if-without-else" class="anchor"></div>
<h1 data-number="9"><span class="header-section-number">9</span> If without else <a href="#if-without-else"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="concept-2" class="anchor"></div>
<h2 data-number="9.1"><span class="header-section-number">9.1</span> Concept <a href="#concept-2"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>This type of statement is very common. It is the same as an if/else statement, without the else block. This statement will create a branch that will be executed if a boolean expression is evaluated to true.</p>
<figure>
<b-img :src="require('@/assets/images/ifwithoutelse.png')" alt="If statement without else[fig:If-statement-without]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">If statement without else<span id="fig:If-statement-without" label="fig:If-statement-without">[fig:If-statement-without]</span></figcaption>
</figure>
<p>In figure <a href="#fig:If-statement-without" data-reference-type="ref" data-reference="fig:If-statement-without">5</a> we have an activity diagram showing an if statement without else. The program will evaluate Boolean expression 1.</p>
<ul>
<li><p>If the expression is evaluated to true, then code one will be executed. After the execution of code 1, the code two block will be executed.</p></li>
<li><p>If the expression is evaluated as false, code two will be executed directly.</p></li>
</ul>
<div id="syntax-2" class="anchor"></div>
<h2 data-number="9.2"><span class="header-section-number">9.2</span> Syntax <a href="#syntax-2"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<figure>
<b-img :src="require('@/assets/images/if_without_else_syntax.png')" alt="If without else syntax"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">If without else syntax</figcaption>
</figure>
<div id="example-2" class="anchor"></div>
<h2 data-number="9.3"><span class="header-section-number">9.3</span> Example <a href="#example-2"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<pre v-highlightjs><code class="go" v-pre >// control-statements/if-without-else/main.go
package main

import (
    &quot;fmt&quot;
    &quot;math/rand&quot;
    &quot;time&quot;
)

func main() {
    rand.Seed(time.Now().UTC().UnixNano())
    var agePaul, ageJohn int = rand.Intn(110), rand.Intn(110)

    fmt.Println(&quot;John is&quot;, ageJohn, &quot;years old&quot;)
    fmt.Println(&quot;Paul is&quot;, agePaul, &quot;years old&quot;)

    if agePaul &gt; ageJohn {
        fmt.Println(&quot;Paul is older than John&quot;)
    }
    fmt.Println(&quot;End of the Program&quot;)
}</code></pre>
<div id="code-explanation-2" class="anchor"></div>
<h3 data-number="9.3.1"><span class="header-section-number">9.3.1</span> Code explanation <a href="#code-explanation-2"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>The first lines are identical to the program of the previous section. In short, we define two int variables that will be initialized with a random value between 0 and 109.</p>
<p>The boolean expression is <span v-highlightjs><code class="go" v-pre style="display: inline">agePaul &gt; ageJohn</code></span>. When this expression is evaluated to true, the statement <span v-highlightjs><code class="go" v-pre style="display: inline">fmt.Println("Paul is older than John")</code></span> is executed.</p>
<div id="code-compilation-and-execution-2" class="anchor"></div>
<h3 data-number="9.3.2"><span class="header-section-number">9.3.2</span> Code compilation and execution <a href="#code-compilation-and-execution-2"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<pre v-highlightjs><code class="go" v-pre >$ go build -o ifWithoutElse conditionals/ifWithoutElse/main.go
$ ./ifWithoutElse
John is 64 years old
Paul is 32 years old
End of the Program</code></pre>
<p>Here is another execution output :</p>
<pre v-highlightjs><code class="go" v-pre >$ ./ifWithoutElse
John is ten years old
Paul is 72 years old
Paul is older than John
End of the Program</code></pre>
<div id="comment-2" class="anchor"></div>
<h3 data-number="9.3.3"><span class="header-section-number">9.3.3</span> Comment <a href="#comment-2"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>You can note that in the first execution, the boolean expression is evaluated to false (John is older than Paul, <span class="math inline">32&lt;64</span> is false). As a consequence, the statement <span v-highlightjs><code class="go" v-pre style="display: inline">fmt.Println("Paul is older than John")</code></span> is not executed.</p>
<p>However, in the second execution, the expression is evaluated to true (<span class="math inline">72&lt;10</span> is true). In that run <strong>Paul is older than John</strong> is printed to screen!</p>
<div id="if-else-if-else" class="anchor"></div>
<h1 data-number="10"><span class="header-section-number">10</span> if / else if / else <a href="#if-else-if-else"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="concept-3" class="anchor"></div>
<h2 data-number="10.1"><span class="header-section-number">10.1</span> Concept <a href="#concept-3"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Sometimes you will need to evaluate more than one boolean expression and create more than two branches.</p>
<p><b-img :src="require('@/assets/images/if_elseif_uml.png')" alt="if/elseif/else activity diagram with 3 boolean expressions[[fig:if/elseif/else-activity-diagram]]{#fig:if/elseif/else-activity-diagram label=“fig:if/elseif/else-activity-diagram”}"  fluid thumbnail class="img-book"></b-img>{#fig:if/elseif/else-activity-diagram width=“80%”}</p>
<p>You can note in the activity diagram (figure <a href="#fig:if/elseif/else-activity-diagram" data-reference-type="ref" data-reference="fig:if/elseif/else-activity-diagram">6</a>) that we have three boolean expressions. When the first is evaluated to true, the code block one will be executed. In the opposite case, the second boolean expression is evaluated. When it’s true, code block two is executed. On the contrary, the third boolean expression is executed... The fourth code block (code 4) is executed when the three boolean expressions are all evaluated to false.</p>
<p>In the diagram, we showed an example with three boolean expressions. There is no maximum number of boolean expressions you can put in an if /else if / else construct. However the more boolean expression you add, the more complicated your code become.</p>
<p>The minimum number of boolean expressions for this construct is two.</p>
<figure>
<b-img :src="require('@/assets/images/If_else_if_2_bool.png')" alt="if/else if/else activity diagram with two boolean expressions"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">if/else if/else activity diagram with two boolean expressions</figcaption>
</figure>
<div id="syntax-3" class="anchor"></div>
<h2 data-number="10.2"><span class="header-section-number">10.2</span> Syntax <a href="#syntax-3"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<figure>
<b-img :src="require('@/assets/images/if_elseif_syntax.png')" alt="if / else if / else syntax"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">if / else if / else syntax</figcaption>
</figure>
<div id="example-3" class="anchor"></div>
<h2 data-number="10.3"><span class="header-section-number">10.3</span> Example <a href="#example-3"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<pre v-highlightjs><code class="go" v-pre >// control-statements/if-else-if/main.go
package main

import (
    &quot;fmt&quot;
    &quot;math/rand&quot;
    &quot;time&quot;
)

func main() {
    rand.Seed(time.Now().UTC().UnixNano())
    var agePaul, ageJohn int = rand.Intn(110), rand.Intn(110)

    fmt.Println(&quot;John is&quot;, ageJohn, &quot;years old&quot;)
    fmt.Println(&quot;Paul is&quot;, agePaul, &quot;years old&quot;)

    if agePaul &gt; ageJohn {
        fmt.Println(&quot;Paul is older than John&quot;)
    } else if agePaul == ageJohn {
        fmt.Println(&quot;Paul and John have the same age&quot;)
    } else {
        fmt.Println(&quot;Paul is younger than John&quot;)
    }
}</code></pre>
<div id="code-explanation-3" class="anchor"></div>
<h3 data-number="10.3.1"><span class="header-section-number">10.3.1</span> Code explanation <a href="#code-explanation-3"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>Like previously, we define and initialize (to a random value) two <span v-highlightjs><code class="go" v-pre style="display: inline">int</code></span> variables (<span v-highlightjs><code class="go" v-pre style="display: inline">agePaul</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">ageJohn</code></span>). Then, the <strong>if / else if / else</strong> begins. The first boolean expression is <span v-highlightjs><code class="go" v-pre style="display: inline">agePaul &gt; ageJohn</code></span>. The second Boolean expression is <span v-highlightjs><code class="go" v-pre style="display: inline">agePaul == ageJohn</code></span>.</p>
<p>If the first boolean condition is evaluated to true, the text <span v-highlightjs><code class="go" v-pre style="display: inline">"Paul is older than John"</code></span> will be printed on the screen, then the program exits. The second boolean expression will not be evaluated.</p>
<p>When the first boolean expression is evaluated to false, the second boolean expression is evaluated. If the second one is true, then we print <span v-highlightjs><code class="go" v-pre style="display: inline">"Paul and John have the same age"</code></span> on the screen.</p>
<p>When this second expression is false, it means that <span v-highlightjs><code class="go" v-pre style="display: inline">agePaul &gt; ageJohn</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">agePaul == ageJohn</code></span> are false. It means that <span v-highlightjs><code class="go" v-pre style="display: inline">agePaul &lt; ageJohn</code></span>. If it’s not clear for you, let’s express it with words :</p>
<ul>
<li><p>Paul is not older than John</p></li>
<li><p>Paul and John have not the same age</p></li>
<li><p>In conclusion, Paul is younger than John.</p></li>
</ul>
<figure>
<b-img :src="require('@/assets/images/if_elseif_else_explanation_code_ex.png')" alt="If / else if / else code example activity diagram"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">If / else if / else code example activity diagram</figcaption>
</figure>
<div id="code-compilation-and-execution-3" class="anchor"></div>
<h3 data-number="10.3.2"><span class="header-section-number">10.3.2</span> Code compilation and execution <a href="#code-compilation-and-execution-3"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<pre v-highlightjs><code class="go" v-pre >$ go build -o ifElseIfElse conditionals/ifElseifElse/main.go
$ ./ifElseIfElse
John is 106 years old
Paul is two years old
Paul is younger than John</code></pre>
<ul>
<li><p>The first and second boolean expressions were evaluated to false in this run.</p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">fmt.Println("Paul is younger than John")</code></span> is executed.</p></li>
</ul>
<div id="comment-3" class="anchor"></div>
<h3 data-number="10.3.3"><span class="header-section-number">10.3.3</span> Comment <a href="#comment-3"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>As you noticed, the if / else if / else statement is verbose. I find it also complex to understand. A switch-case statement can be easier to understand.</p>
<div id="switch-case" class="anchor"></div>
<h1 data-number="11"><span class="header-section-number">11</span> Switch case <a href="#switch-case"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="concept-4" class="anchor"></div>
<h2 data-number="11.1"><span class="header-section-number">11.1</span> Concept <a href="#concept-4"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>With a switch statement, you can create branches in your program that will be executed depending on the value of an expression. This expression is called the “switch expression”. Here are some examples of expressions we can use in a switch case :</p>
<ul>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">ageJohn</code></span></p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">agePaul + ageJohn</code></span></p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">agePaul * ageJohn</code></span> (multiplication)</p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">agePaul / ageJohn</code></span> (division)</p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">agePaul &gt; ageJohn</code></span></p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">fmt.Println("this is a text")</code></span></p></li>
</ul>
<p>Those expressions will be evaluated then compared to the value of other expressions. If there is equality, the branch is executed. Let’s visualize it with an activity diagram (figure <a href="#fig:Switch-case-activity" data-reference-type="ref" data-reference="fig:Switch-case-activity">7</a>)</p>
<figure>
<b-img :src="require('@/assets/images/switchCase.png')" alt="Switch case activity diagram[fig:Switch-case-activity]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Switch case activity diagram<span id="fig:Switch-case-activity" label="fig:Switch-case-activity">[fig:Switch-case-activity]</span></figcaption>
</figure>
<p>Let’s detail what’s going on in this diagram :</p>
<ul>
<li><p>When <strong>expression</strong> is evaluated to <strong>value 1</strong> then the <strong>code 1</strong> is executed.</p></li>
<li><p>When <strong>expression</strong> is evaluated to <strong>value 2</strong> then the <strong>code 2</strong> is executed.</p></li>
<li><p>When <strong>expression</strong> is evaluated to <strong>value 3</strong> then the <strong>code 3</strong> is executed.</p></li>
<li><p>When the expression is not evaluated to <strong>value 1</strong>, <strong>value 2</strong> nor <strong>value 3</strong> then code 4 is executed. As stated in the diagram, this is the default case.</p></li>
</ul>
<div id="syntax-4" class="anchor"></div>
<h2 data-number="11.2"><span class="header-section-number">11.2</span> Syntax <a href="#syntax-4"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<figure>
<b-img :src="require('@/assets/images/switch_case_syntax.png')" alt="Switch case syntax[fig:Switch-case-syntax]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Switch case syntax<span id="fig:Switch-case-syntax" label="fig:Switch-case-syntax">[fig:Switch-case-syntax]</span></figcaption>
</figure>
<ul>
<li><p>In the switch, you can note that you have two <strong>OPTIONAL</strong> elements:</p>
<ul>
<li><p>a statement followed by a semicolon</p></li>
<li><p>a main expression</p></li>
</ul></li>
<li><p>Then you have the series of cases. Each case has the same format :</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">case expression :</code></span></p></li>
<li><p>You can also put a <strong>list of expressions</strong> in a case :</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">case expression1, expression2, expression3 :</code></span></p></li>
<li><p>The final part of the switch case is the default case. It is optional.</p></li>
</ul>
<div id="example-4" class="anchor"></div>
<h2 data-number="11.3"><span class="header-section-number">11.3</span> Example <a href="#example-4"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<pre v-highlightjs><code class="go" v-pre >//... define agePaul and ageJohn

// switch expression
switch ageJohn {  
case 10:
   fmt.Println(&quot;John is 10 years old&quot;)
case 20:
   fmt.Println(&quot;John is 20 years old&quot;)
case 100:
   fmt.Println(&quot;John is 100 years old&quot;)
default:
   fmt.Println(&quot;John has neither 10,20 nor 100 years old&quot;)
}

// switch statement; expression
switch ageSum := ageJohn + agePaul; ageSum { 
case 10:
   fmt.Println(&quot;ageJohn + agePaul = 10&quot;)
case 20, 30, 40: //*\label{switchMulti}
   fmt.Println(&quot;ageJohn + agePaul = 20 or 30 or 40&quot;)
case 2 * agePaul:
   fmt.Println(&quot;ageJohn + agePaul = 2 times agePaul&quot;)
}

// switch (without statement, without expression)
switch { 
case agePaul &gt; ageJohn:
   fmt.Println(&quot;agePaul &gt; ageJohn&quot;)
case agePaul == ageJohn:
   fmt.Println(&quot;agePaul == ageJohn&quot;)
case agePaul &lt; ageJohn:
   fmt.Println(&quot;agePaul &lt; ageJohn&quot;)
}</code></pre>
<div id="code-explanation-4" class="anchor"></div>
<h4 data-number="11.3.0.1"><span class="header-section-number">11.3.0.1</span> Code explanation <a href="#code-explanation-4"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>In this example, we omitted the definition of the <span v-highlightjs><code class="go" v-pre style="display: inline">main</code></span> function (and also the package definition). The definition of <span v-highlightjs><code class="go" v-pre style="display: inline">agePaul</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">ageJohn</code></span> was also omitted. You can find the complete code in the companion code repository</p>
<ul>
<li><p>We have defined a switch statement with the expression <span v-highlightjs><code class="go" v-pre style="display: inline">ageJohn</code></span></p>
<ul>
<li><p>The program will evaluate the value of <span v-highlightjs><code class="go" v-pre style="display: inline">ageJohn</code></span> and compare it to the value of expressions in the cases :</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">10``20</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">100</code></span></p>
<ul>
<li>Here our expressions are just values.</li>
</ul></li>
<li><p>If the value of <span v-highlightjs><code class="go" v-pre style="display: inline">ageJohn</code></span> is not equal to 10, 20, or 100, then the default case is executed</p></li>
</ul></li>
<li><p>Tehn, we have defined a switch statement with :</p>
<ul>
<li><p>A first statement (which is a variable assignation) <span v-highlightjs><code class="go" v-pre style="display: inline">ageSum := ageJohn + agePaul</code></span></p></li>
<li><p>Then our expression is <span v-highlightjs><code class="go" v-pre style="display: inline">ageSum</code></span>.</p></li>
<li><p>The program will first execute the statement that defines <span v-highlightjs><code class="go" v-pre style="display: inline">ageSum</code></span> then it will evaluate <span v-highlightjs><code class="go" v-pre style="display: inline">ageSum</code></span> and compare it to the value of the expressions define in the cases :</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">10``20, 30, 40</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">2 * agePaul</code></span></p>
<ul>
<li><p>The second case (on line <a href="#switchMulti" data-reference-type="ref" data-reference="switchMulti">[switchMulti]</a>) is a list of expressions.</p></li>
<li><p>The third case <span v-highlightjs><code class="go" v-pre style="display: inline">2 * agePaul</code></span> is an expression that needs to be evaluated.</p></li>
</ul></li>
</ul></li>
<li><p>Then, we have defined a switch statement without any expression.</p></li>
<li><p>A switch without an expression is equivalent to compare cases to the value. <span v-highlightjs><code class="go" v-pre style="display: inline">true</code></span>.</p></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >switch {
case agePaul &gt; ageJohn:
   //...
}

// is equivalent to

switch true {
case agePaul &gt; ageJohn:
   //...
}</code></pre>
<ul>
<li>Boolean expressions are expected in the cases (when no expression is provided in the header of the switch)</li>
</ul>
<div id="code-compilation-and-execution-4" class="anchor"></div>
<h4 data-number="11.3.0.2"><span class="header-section-number">11.3.0.2</span> Code compilation and execution <a href="#code-compilation-and-execution-4"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<pre v-highlightjs><code class="go" v-pre >$ go build -o switchCase conditionals/switch/main.go
$ ./switchCase
John is 92 years old
Paul is 68 years old
John has neither 10,20 nor 100 years old
agePaul &lt; ageJohn</code></pre>
<div id="comment-4" class="anchor"></div>
<h4 data-number="11.3.0.3"><span class="header-section-number">11.3.0.3</span> Comment <a href="#comment-4"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>The switch case is <strong>intensively used</strong> into go programs. It’s easy to read, and the syntax is also easy to learn. This is a must-have!</p>
<div id="for-statement-with-a-single-condition" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="12"><span class="header-section-number">12</span> For statement with a single condition <a href="#for-statement-with-a-single-condition"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="concept-5" class="anchor"></div>
<h2 data-number="12.1"><span class="header-section-number">12.1</span> Concept <a href="#concept-5"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>With a for statement, you can repeat the execution of a block of code several time. The number of executions depends on a condition. While the condition is not fulfilled, the block of code is executed. When the condition is fulfilled, the repetition stops.</p>
<figure>
<b-img :src="require('@/assets/images/concept_for_loop_single_condition.png')" alt="For statement single condition"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">For statement single condition</figcaption>
</figure>
<p>The condition is a boolean expression; for instance “is the variable X equal to 42 ?” or “is the variable X greater than 47” or “was the email sent?”. The boolean expression will return either true or false.</p>
<div id="syntax-5" class="anchor"></div>
<h2 data-number="12.2"><span class="header-section-number">12.2</span> Syntax <a href="#syntax-5"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<figure>
<b-img :src="require('@/assets/images/syntax_for_loop_single_condition.png')" alt="Syntax for statement with a single condition"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Syntax for statement with a single condition</figcaption>
</figure>
<p>A for statement with a single condition is composed of the keyword <span v-highlightjs><code class="go" v-pre style="display: inline">for</code></span>. Right after the keyword, we find the condition, which is followed by an open curly brace. Instructions that should be repeated are added after the opening brace. The for statement ends with a closing curly brace.</p>
<div id="example-5" class="anchor"></div>
<h2 data-number="12.3"><span class="header-section-number">12.3</span> Example <a href="#example-5"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<pre v-highlightjs><code class="go" v-pre >// control-statements/for-statement/main.go
package main

import &quot;fmt&quot;

func main() {
    const emailToSend = 3
    emailSent := 0

    for emailSent &lt; emailToSend {
        fmt.Println(&quot;sending email..&quot;)
        emailSent++ //*\label{forWithSingle2}
    }
    fmt.Println(&quot;end of program&quot;)
}</code></pre>
<div id="code-explanation-5" class="anchor"></div>
<h3 data-number="12.3.1"><span class="header-section-number">12.3.1</span> Code explanation <a href="#code-explanation-5"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<ul>
<li><p>We define a constant of type integer <span v-highlightjs><code class="go" v-pre style="display: inline">emailToSend</code></span>. The value of the constant is set to <span v-highlightjs><code class="go" v-pre style="display: inline">3</code></span>.</p></li>
<li><p>Next, we define a variable <span v-highlightjs><code class="go" v-pre style="display: inline">emailSent</code></span> wich is initialized with the value <span v-highlightjs><code class="go" v-pre style="display: inline">0</code></span>.</p></li>
<li><p>Then we create a for loop :</p>
<ul>
<li><p>The condition of the for loop is <span v-highlightjs><code class="go" v-pre style="display: inline">emailSent &lt; emailToSend</code></span></p></li>
<li><p>The runtime evaluates this condition.</p></li>
<li><p>If the evaluation result is true, then the statements</p>
<pre v-highlightjs><code class="go" v-pre >fmt.Println(&quot;sending email..&quot;)</code></pre>
<p>and</p>
<pre v-highlightjs><code class="go" v-pre >emailSent++</code></pre>
<p>will be executed.</p></li>
<li><p>When the condition is evaluated to false, the previous statements are not executed.</p></li>
</ul></li>
</ul>
<div id="code-compilation-and-execution-5" class="anchor"></div>
<h3 data-number="12.3.2"><span class="header-section-number">12.3.2</span> Code compilation and execution <a href="#code-compilation-and-execution-5"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<pre v-highlightjs><code class="go" v-pre >$ go build -o forLoopSingleCond conditionals/forLoopSingleCondition/main.go
$ ./forLoopSingleCond
sending email..
sending email..
sending email..
end of program</code></pre>
<div id="comment-5" class="anchor"></div>
<h3 data-number="12.3.3"><span class="header-section-number">12.3.3</span> Comment <a href="#comment-5"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<ul>
<li><p>Double-check your boolean expression to be sure that it will be false at some point in your program.</p></li>
<li><p>If you have a boolean expression that is always true, your program will run <strong>indefinitely</strong>.</p></li>
<li><p>You might have noticed, that syntactically the condition is optional. We will cover this later.</p></li>
</ul>
<div id="for-statement-with-a-for-clause" class="anchor"></div>
<h1 data-number="13"><span class="header-section-number">13</span> For statement with a for clause <a href="#for-statement-with-a-for-clause"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>This type of statement is conceptually the same as the previous one. In this for loop, we will need to add two statements :</p>
<ul>
<li><p>The init statement</p></li>
<li><p>The post statement.</p></li>
</ul>
<div id="concept-6" class="anchor"></div>
<h2 data-number="13.1"><span class="header-section-number">13.1</span> Concept <a href="#concept-6"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>With a for loop, you can repeat the execution of instructions. The number of repetitions is usually defined with a counter (which is a variable). When the counter’s value exceeds a defined value, the repetition stops.</p>
<figure>
<b-img :src="require('@/assets/images/concept_for_loop_with_counter.png')" alt="For statement with a for clause[fig:For-statement-with-for-caluse]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">For statement with a for clause<span id="fig:For-statement-with-for-caluse" label="fig:For-statement-with-for-caluse">[fig:For-statement-with-for-caluse]</span></figcaption>
</figure>
<p>In figure <a href="#fig:For-statement-with-for-caluse" data-reference-type="ref" data-reference="fig:For-statement-with-for-caluse">9</a> you can see an activity diagram that represents a for statement. Let’s detail the process that we have here :</p>
<ol type="1">
<li><p>We first define a <span class="math inline">\text{MAX}</span> constant. It’s a number. For instance we initialize it with the value <span class="math inline">2</span>.</p></li>
<li><p>Then we define a <span class="math inline">\text{loopCounter}</span> variable. We initialize it to the value <span class="math inline">1</span>.</p></li>
<li><p>We test if <span class="math inline">\text{loopCounter}</span> exceeds <span class="math inline">\text{MAX}</span> this is not the case (<span class="math inline">\text{loopCounter}=1</span> ; <span class="math inline">MAX=2</span>)</p></li>
<li><p><strong>Execute code block</strong></p></li>
<li><p>We add <span class="math inline">1</span> to <span class="math inline">\text{loopCounter}</span>. New value of <span class="math inline">\text{loopCounter}</span> is 2 (<span class="math inline">1+1=2)</span></p></li>
<li><p>We test if <span class="math inline">\text{loopCounter}</span> exceeds<span class="math inline">\text{MAX}</span> this is not the case (<span class="math inline">\text{loopCounter}=2</span> ; <span class="math inline">MAX=2</span>), they have the same value</p></li>
<li><p><strong>Execute code block</strong></p></li>
<li><p>We add <span class="math inline">1</span> to <span class="math inline">\text{loopCounter}</span>. New value of <span class="math inline">\text{loopCounter}</span> is <span class="math inline">3</span> (<span class="math inline">2+1=3)</span></p></li>
<li><p>We test if <span class="math inline">\text{loopCounter}</span> exceeds <span class="math inline">\text{MAX}</span>. It’s true this time (<span class="math inline">\text{loopCounter}=3</span> ; <span class="math inline">MAX=2</span>).</p></li>
<li><p>End of the program</p></li>
</ol>
<p>How many times the code block has been executed? 2 times (this is the value of <span class="math inline">\text{MAX}</span> ).</p>
<p>Try to do the same exercise, but this time initialize the value of<span class="math inline">\text{loopCounter}</span> to 0. (the solution is at the end of the chapter)</p>
<div id="vocabulary" class="anchor"></div>
<h3 data-number="13.1.1"><span class="header-section-number">13.1.1</span> Vocabulary : <a href="#vocabulary"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>The for loop brings a specific vocabulary with it :</p>
<ul>
<li><p><strong>The initialization statement</strong>. In this statement, we initialize the loop counter.</p>
<ul>
<li>Example : definition and initialization of the <span class="math inline">\text{loopCounter}</span> variable.</li>
</ul></li>
<li><p><strong>The condition :</strong> this is a boolean expression (when evaluated, it should return true or false). The condition will be evaluated at the beginning of each loop.</p>
<ul>
<li>Example : test if <span class="math inline">\text{loopCounter}</span> exceeds<span class="math inline">\text{MAX}</span></li>
</ul></li>
<li><p><strong>The post statement</strong> : this is a statement that will be executed <strong>AFTER</strong> each loop.</p>
<ul>
<li>Example : add <span class="math inline">1</span> to <span class="math inline">\text{loopCounter}</span></li>
</ul></li>
</ul>
<div id="syntax-6" class="anchor"></div>
<h2 data-number="13.2"><span class="header-section-number">13.2</span> Syntax <a href="#syntax-6"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<figure>
<b-img :src="require('@/assets/images/syntax_for_loop_with_counter.png')" alt="For statement with a for clause[fig:For-statement-with-for-clause-syntax]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">For statement with a for clause<span id="fig:For-statement-with-for-clause-syntax" label="fig:For-statement-with-for-clause-syntax">[fig:For-statement-with-for-clause-syntax]</span></figcaption>
</figure>
<ul>
<li><p>The keyword used here is <span v-highlightjs><code class="go" v-pre style="display: inline">for</code></span></p></li>
<li><p>After the keyword, you will add :</p>
<ul>
<li><p>The init statement</p></li>
<li><p>A semicolon</p></li>
<li><p>The condition</p></li>
<li><p>A semicolon</p></li>
<li><p>The post statement.</p></li>
</ul></li>
<li><p>Note that the init statement, condition, and post statement are optional. We will cover this case in the next section, so do not worry about that.</p></li>
<li><p>The instruction block (that will be executed several times) lives inside the open and closing curly braces.</p></li>
</ul>
<div id="example-6" class="anchor"></div>
<h2 data-number="13.3"><span class="header-section-number">13.3</span> Example <a href="#example-6"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<pre v-highlightjs><code class="go" v-pre >// control-statements/for-statement/main.go
package main

import (
    &quot;fmt&quot;
    &quot;math/rand&quot;
    &quot;time&quot;
)

func main() {
    rand.Seed(time.Now().UTC().UnixNano())
    var ageJohn int = rand.Intn(110)
    fmt.Println(&quot;John is&quot;, ageJohn, &quot;years old&quot;)

    for i := 0; i &lt; ageJohn; i++ {
        fmt.Println(&quot;interation N&quot;, i)
    }

}</code></pre>
<div id="code-explanation-6" class="anchor"></div>
<h3 data-number="13.3.1"><span class="header-section-number">13.3.1</span> Code explanation <a href="#code-explanation-6"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>We define the variable <span v-highlightjs><code class="go" v-pre style="display: inline">ageJohn</code></span> and we initialize it to a random value between 0 and 109. After printing its value with the statement <span v-highlightjs><code class="go" v-pre style="display: inline">fmt.Println("John is", ageJohn, "years old")</code></span> we add a for statement. This for statement is composed of the following elements : <span v-highlightjs><code class="go" v-pre style="display: inline">i := 0</code></span> this is the init statement. It will be executed at the <strong>beginning</strong> of the for loop</p>
<ul>
<li><p>We create and initialize a variable <span v-highlightjs><code class="go" v-pre style="display: inline">i</code></span></p>
<ul>
<li>This is our counter. (Usually, counters are named: i,j or k)</li>
</ul></li>
</ul>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">i &lt; ageJohn</code></span> is the condition.</p>
<ul>
<li><p>This is a boolean expression, which means that it will be evaluated to true or false at runtime</p>
<ul>
<li><p>This boolean expression will be evaluated at each loop.</p></li>
<li><p>If it returns false, the loop stops.</p></li>
</ul></li>
</ul>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">i++</code></span> is the post statement.</p>
<ul>
<li><p>It will be executed after each loop.</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">i++</code></span> is a shorthand for <span v-highlightjs><code class="go" v-pre style="display: inline">i = i +1</code></span></p>
<ul>
<li>We increment the value of i; in other words, we add 1 to <span v-highlightjs><code class="go" v-pre style="display: inline">i</code></span>.</li>
</ul></li>
</ul>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">fmt.Println("interation N", i)</code></span> will be executed at each loop. The program will print “interation nX” ageJohn times. If ageJohn is 3, then we will print on the screen :</p>
<ul>
<li><p>interation n 0</p></li>
<li><p>iteration n 1</p></li>
<li><p>iteration n 2</p></li>
</ul>
<p>We printed it three times. The value of i is 0, then 1, and then 2.</p>
<div id="code-compilation-and-execution-6" class="anchor"></div>
<h3 data-number="13.3.2"><span class="header-section-number">13.3.2</span> Code compilation and execution <a href="#code-compilation-and-execution-6"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<pre v-highlightjs><code class="go" v-pre >$ go build -o forLoopClause conditionals/forLoopClause/main.go
$ ./forLoopClause
John is 7 years old
interation N 0
interation N 1
interation N 2
interation N 3
interation N 4
interation N 5
interation N 6</code></pre>
<div id="comment-6" class="anchor"></div>
<h3 data-number="13.3.3"><span class="header-section-number">13.3.3</span> Comment <a href="#comment-6"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<ul>
<li><p>The first value of i is 0 and not 1; remember that our init statement is <span v-highlightjs><code class="go" v-pre style="display: inline">i := 0</code></span>.</p></li>
<li><p>If the init statement is <span v-highlightjs><code class="go" v-pre style="display: inline">i := 1</code></span> The first value of i is 1. The first sentence printed on screen would be <strong>interation N° 1</strong></p>
<ul>
<li><p>This is often deconcerting for beginners. In the real world, when we start an enumeration with the value one and not 0!</p></li>
<li><p>This is (often) not the case in computer science. Indexes usually start with the value 0.</p></li>
</ul></li>
<li><p>For loops with range clauses are used intensively.</p></li>
</ul>
<div id="practical-applications" class="anchor"></div>
<h1 data-number="14"><span class="header-section-number">14</span> Practical applications <a href="#practical-applications"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="mission" class="anchor"></div>
<h2 data-number="14.1"><span class="header-section-number">14.1</span> Mission <a href="#mission"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>We will build a hotel application. We will begin with the front desk program used by receptionists. At the front desk, the receptionist will welcome customers. Clients will ask the receptionist for an available room. He needs to know :</p>
<ul>
<li><p>How many rooms are available now</p></li>
<li><p>For how long each room is available</p></li>
</ul>
<p>We will write a program that will do that based on fake data. In figure <a href="#fig:Wireframe-control" data-reference-type="ref" data-reference="fig:Wireframe-control">11</a> you can see the wireframes of the application.</p>
<p>Note that we have presented the same application in two different states.</p>
<figure>
<b-img :src="require('@/assets/images/application_control_flow.png')" alt="Wireframes of the hotel management application[fig:Wireframe-control]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Wireframes of the hotel management application<span id="fig:Wireframe-control" label="fig:Wireframe-control">[fig:Wireframe-control]</span></figcaption>
</figure>
<div id="remarks" class="anchor"></div>
<h3 data-number="14.1.1"><span class="header-section-number">14.1.1</span> Remarks <a href="#remarks"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<ul>
<li><p>The total number of rooms is a constant equal to 134.</p></li>
<li><p>We will generate the number of rooms available randomly.</p></li>
<li><p>For simplicity: the available rooms have a fake number that starts at 110</p>
<ul>
<li>If three rooms are available, then the rooms are numbered: 110, 111, 112</li>
</ul></li>
<li><p>The number of people that you can put in a room and the number of nights available will be generated randomly</p>
<ul>
<li><p>Minimum is 1</p></li>
<li><p>Maximum is 10</p></li>
</ul></li>
<li><p>The <strong>occupancy rate</strong> is defined as follow :</p>
<p><span class="math inline">\text{occupancy rate}=\frac{\text{Number of rooms occupied}}{\text{Total number of rooms}}</span></p></li>
<li><p>The <strong>occupancy level</strong> is defined as follow :</p>
<ul>
<li><p>From 0% to 30% occupancy rate : Low</p></li>
<li><p>From 30% to 60% occupancy rate: Medium</p></li>
<li><p>From 60% to 100% occupancy rate: High</p></li>
</ul></li>
</ul>
<div id="solution-1" class="anchor"></div>
<h2 data-number="14.2"><span class="header-section-number">14.2</span> Solution <a href="#solution-1"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<pre v-highlightjs><code class="go" v-pre >// control-statements/application-solution-2/main.go
package main

import (
    &quot;fmt&quot;
    &quot;math/rand&quot;
    &quot;time&quot;
)

func main() {
    const hotelName = &quot;Gopher Paris Inn&quot;
    const totalRooms = 134
    const firstRoomNumber = 110

    rand.Seed(time.Now().UTC().UnixNano())
    roomsOccupied := rand.Intn(totalRooms)
    roomsAvailable := totalRooms - roomsOccupied

    occupancyRate := (float64(roomsOccupied) / float64(totalRooms)) * 100
    var occupancyLevel string
    if occupancyRate &gt; 70 {
        occupancyLevel = &quot;High&quot;
    } else if occupancyRate &gt; 20 {
        occupancyLevel = &quot;Medium&quot;
    } else {
        occupancyLevel = &quot;Low&quot;
    }

    fmt.Println(&quot;Hotel:&quot;, hotelName)
    fmt.Println(&quot;Number of rooms&quot;, totalRooms)
    fmt.Println(&quot;Rooms available&quot;, roomsAvailable)
    fmt.Println(&quot;                  Occupancy Level:&quot;, occupancyLevel)
    fmt.Printf(&quot;                  Occupancy Rate: %0.2f %%\n&quot;, occupancyRate)

    if roomsAvailable &gt; 0 {
        fmt.Println(&quot;Rooms:&quot;)
        for i := 0; roomsAvailable &gt; i; i++ {
            roomNumber := firstRoomNumber + i
            size := rand.Intn(6) + 1
            nights := rand.Intn(10) + 1
            fmt.Println(roomNumber, &quot;:&quot;, size, &quot;people /&quot;, nights, &quot; nights &quot;)
        }
    } else {
        fmt.Println(&quot;No rooms available for tonight&quot;)
    }

}</code></pre>
<div id="explanations" class="anchor"></div>
<h2 data-number="14.3"><span class="header-section-number">14.3</span> Explanations <a href="#explanations"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<div id="constants" class="anchor"></div>
<h3 data-number="14.3.1"><span class="header-section-number">14.3.1</span> Constants <a href="#constants"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>We begin by defining three constants :</p>
<ul>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">hotelName</code></span> hold the name of our hotel</p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">totalRooms</code></span> the total number of rooms</p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">firstRoomNumber</code></span> the first room number. Rooms will be enumerated 110, 111, ... We need to store the starting point of the later enumeration.</p></li>
</ul>
<p>Why constants? Because those values will not evolve during the program runtime. They are fixed.</p>
<p>Note that those three constants are <strong>untyped</strong>.</p>
<div id="generation-of-a-random-integer-the-number-of-rooms-currently-rented" class="anchor"></div>
<h3 data-number="14.3.2"><span class="header-section-number">14.3.2</span> Generation of a random integer (the number of rooms currently rented) <a href="#generation-of-a-random-integer-the-number-of-rooms-currently-rented"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>Next, because we will use a random number, we need to seed the random number generator with the following instruction <span v-highlightjs><code class="go" v-pre style="display: inline">rand.Seed(time.Now().UTC().UnixNano())</code></span>.</p>
<p>The variable <span v-highlightjs><code class="go" v-pre style="display: inline">roomsOccupied</code></span> represents the number of rooms that clients use. It’s a random number between 0 and <span v-highlightjs><code class="go" v-pre style="display: inline">totalRooms</code></span>. To generate such a number, we use the instruction : <span v-highlightjs><code class="go" v-pre style="display: inline">rand.Intn(totalRooms)</code></span></p>
<div id="occupancy-rate-int-or-float" class="anchor"></div>
<h3 data-number="14.3.3"><span class="header-section-number">14.3.3</span> Occupancy rate: int or float? <a href="#occupancy-rate-int-or-float"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>A rate is a percentage. Mathematically this is a floating-point number that will vary from 0 to 1.</p>
<ul>
<li><p>1 is equivalent to 100%</p></li>
<li><p>0,5655 is equivalent to 56,55%</p></li>
<li><p>0,81115 is equivalent to 81,12% (we rounded the number).</p></li>
</ul>
<p>To get a percentage, you need to multiply by 100. To get the percentage value, you might be tempted to write the following code :</p>
<pre v-highlightjs><code class="go" v-pre >occupancyRate := (roomsOccupied / totalRooms) * 100</code></pre>
<p>With this code <span v-highlightjs><code class="go" v-pre style="display: inline">occupancyRate</code></span> is an integer. The variable is defined and assigned in the same statement. Its type is not written. Consequently, its type will be inferred by the value assigned to it. In that case, the value is <span v-highlightjs><code class="go" v-pre style="display: inline">(roomsOccupied / totalRooms) * 100</code></span>. Let’s decompose : <span v-highlightjs><code class="go" v-pre style="display: inline">roomsOccupied</code></span> is of type int (the result of <span v-highlightjs><code class="go" v-pre style="display: inline">rand.Intn(totalRooms)</code></span>)</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">totalRooms</code></span> is an untyped int constant.</p>
<ul>
<li>100 is an integer</li>
</ul>
<p>As a consequence, the value of <span v-highlightjs><code class="go" v-pre style="display: inline">(roomsOccupied / totalRooms) * 100</code></span> is an int.</p>
<p>We do not want an integer; with an integer, we will lose the numbers after the decimal separator. Instead, we want to use a floating-point number. We have to choose between two primitive types :</p>
<ul>
<li><p>float64</p></li>
<li><p>float32</p></li>
</ul>
<p>We have to know the min and maximum values of both types to choose from. With that data, we can find the best fit :</p>
<ul>
<li><p>float32</p>
<ul>
<li><p>Minimum : <span class="math inline">1.401298464324817070923729583289916131280\times10^{-45}</span></p></li>
<li><p>Maximum : <span class="math inline">3.40282346638528859811704183484516925440\times10^{38}</span></p></li>
</ul></li>
<li><p>float64</p>
<ul>
<li><p>Minimum : <span class="math inline">4.940656458412465441765687928682213723651\times10^{-324}</span></p></li>
<li><p>Maximum : <span class="math inline">1.797693134862315708145274237317043567981\times10^{308}</span></p></li>
</ul></li>
</ul>
<p><strong>float32</strong> suits our needs perfectly.</p>
<p>Where did I find those numbers? They are constants defined in the source code of go (math package) you can check them with this link: https://golang.org/pkg/math/#pkg-constants</p>
<div id="a-small-digression-the-scientific-notation" class="anchor"></div>
<h3 data-number="14.3.4"><span class="header-section-number">14.3.4</span> A small digression: the scientific notation <a href="#a-small-digression-the-scientific-notation"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>You might get confused with the power of ten notation (<span class="math inline">\times10^{308}</span>). Let’s take an example to understand it : <span class="math display">1.55\times10^{3}</span></p>
<p><span class="math display">=1.55\times10\times10\times10</span></p>
<p><span class="math display">=15.5\times10\times10</span></p>
<p><span class="math display">=155\times10</span></p>
<p><span class="math display">=1550</span></p>
<p>Note that the decimal separator was shifted to the right three times.</p>
<p>Let’s take another example: a light year (the distance the light will travel in a year) : <span class="math display">9.461\times10^{15}</span></p>
<p><span class="math display">=9,461,000,000,000,000</span></p>
<p>This is a very large number. It’s more convenient to write it with powers of 10!</p>
<p>You understand now that <span class="math inline">1.797693134862315708145274237317043567981\times10^{308}</span> is a very, very large number! To write it, we need to shift the decimal separator to the right 308 times!</p>
<p>When the power of ten is negative, we have to shift the decimal separator to the left. <span class="math inline">4.940656458412465441765687928682213723651\times10^{-324}</span> is a very, very, very small number.</p>
<div id="conversion-of-an-int-to-a-float32" class="anchor"></div>
<h3 data-number="14.3.5"><span class="header-section-number">14.3.5</span> Conversion of an int to a float32 <a href="#conversion-of-an-int-to-a-float32"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>To convert an int to a float32, we use the following syntax :</p>
<pre v-highlightjs><code class="go" v-pre >occupancyRate := (float32(roomsOccupied) / float32(totalRooms)) * 100</code></pre>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">float32(roomsOccupied)</code></span> is a <span v-highlightjs><code class="go" v-pre style="display: inline">float32</code></span>, same for <span v-highlightjs><code class="go" v-pre style="display: inline">float32(totalRooms)</code></span>. As a consequence, the type of occupancy rate is <span v-highlightjs><code class="go" v-pre style="display: inline">float32</code></span> because its initialization value is a <span v-highlightjs><code class="go" v-pre style="display: inline">float32</code></span> :</p>
<ul>
<li><p>a division of two <span v-highlightjs><code class="go" v-pre style="display: inline">float32</code></span> gives a <span v-highlightjs><code class="go" v-pre style="display: inline">float32</code></span></p></li>
<li><p>the multiplication of a <span v-highlightjs><code class="go" v-pre style="display: inline">float32</code></span> by 100 is still a <span v-highlightjs><code class="go" v-pre style="display: inline">float32</code></span></p></li>
</ul>
<div id="occupancylevel-if-else-if-else" class="anchor"></div>
<h3 data-number="14.3.6"><span class="header-section-number">14.3.6</span> OccupancyLevel : if / else if / else <a href="#occupancylevel-if-else-if-else"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>The <span v-highlightjs><code class="go" v-pre style="display: inline">occupancyLevel</code></span> depends on the value of the <span v-highlightjs><code class="go" v-pre style="display: inline">occupancyRate</code></span> variable.</p>
<ul>
<li><p>From 0 to 20 =&gt; Low</p></li>
<li><p>From 20 to 70 =&gt; Medium</p></li>
<li><p>From 70 to 100 =&gt; High</p></li>
</ul>
<p>We used an if / else if / else statement to set the value of occupancyLevel. Why? Because we have three levels, three possible values. The if / else if / else is a control statement that’s perfectly adapted. Here is an extract of the source code :</p>
<pre v-highlightjs><code class="go" v-pre >var occupancyLevel string
if occupancyRate &gt; 70 {
   occupancyLevel = &quot;High&quot;
} else if occupancyRate &gt; 20 {
   occupancyLevel = &quot;Medium&quot;
} else {
   occupancyLevel = &quot;Low&quot;
}</code></pre>
<p>We create the variable <span v-highlightjs><code class="go" v-pre style="display: inline">occupancyLevel</code></span> of type <span v-highlightjs><code class="go" v-pre style="display: inline">string</code></span>. If the occupancyRate is higher than 70, then the occupancyLevel is High. If <span v-highlightjs><code class="go" v-pre style="display: inline">occupancyRate &gt; 70</code></span> is evaluated to false, then it means that the value of the variable is less or equal to 70. We only know that it’s between 0 and 70.</p>
<p>The second boolean expression is <span v-highlightjs><code class="go" v-pre style="display: inline">occupancyRate &gt; 20</code></span>. This boolean expression is only evaluated when the value of occupancyRate is between 0 and 70. If true, it means that the value is between 20 and 70, which is equivalent to an <span v-highlightjs><code class="go" v-pre style="display: inline">occupancyLevel</code></span> equal to Medium</p>
<p>If <span v-highlightjs><code class="go" v-pre style="display: inline">occupancyRate</code></span> is not greater than 20, it means that it’s between 0 and 20. In this case, we have a Low level.</p>
<div id="occupancylevel-switch-case-alternative" class="anchor"></div>
<h3 data-number="14.3.7"><span class="header-section-number">14.3.7</span> OccupancyLevel : switch case alternative <a href="#occupancylevel-switch-case-alternative"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<pre v-highlightjs><code class="go" v-pre >switch {
case occupancyRate &gt; 70:
   occupancyLevel = &quot;High&quot;
case occupancyRate &gt; 20:
   occupancyLevel = &quot;Medium&quot;
default:
   occupancyLevel = &quot;Low&quot;
}</code></pre>
<p>Note that this switch case has no expression and no statement. It’s equivalent to :</p>
<pre v-highlightjs><code class="go" v-pre >switch true {
//...
}</code></pre>
<p>We will find the first case that will be equal to true. If none found, the default block is executed. (similar to the else block).</p>
<p>What we can also do is to delete the default case by initializing the value of <span v-highlightjs><code class="go" v-pre style="display: inline">occupancyLevel</code></span> to <span v-highlightjs><code class="go" v-pre style="display: inline">"Low"</code></span>:</p>
<pre v-highlightjs><code class="go" v-pre >occupancyLevel := &quot;Low&quot;
switch {
case occupancyRate &gt; 70:
   occupancyLevel = &quot;High&quot;
case occupancyRate &gt; 20:
   occupancyLevel = &quot;Medium&quot;
}</code></pre>
<p>The variable’s value will be overridden in the High and Medium case.</p>
<div id="displaying-information-on-the-screen" class="anchor"></div>
<h3 data-number="14.3.8"><span class="header-section-number">14.3.8</span> Displaying information on the screen <a href="#displaying-information-on-the-screen"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>We used the package fmt :</p>
<pre v-highlightjs><code class="go" v-pre >fmt.Println(&quot;Hotel:&quot;, hotelName)
fmt.Println(&quot;Number of rooms&quot;, totalRooms)
fmt.Println(&quot;Rooms available&quot;, roomsAvailable)
fmt.Println(&quot;                  Occupancy Level:&quot;, occupancyLevel)
fmt.Printf(&quot;                  Occupancy Rate: %0.2f %%\n&quot;, occupancyRate)</code></pre>
<p>Note that we used Printf. This method will print a string like Println. Interestingly, you can inject variable/constant values inside a string. <span v-highlightjs><code class="go" v-pre style="display: inline">"                  Occupancy Rate: %0.2f %%\n"</code></span> is called the “format specifier”. It’s a specification of the format. In this specification, we can add <strong>format verbs</strong>. A format verb is used to indicate to the printer that we want to inject in the printed output a value. The verb indicates how we want it to format the value.</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">%0.2f</code></span> indicate that we want to print a float value (<strong>f</strong>) and that we want to display only two decimals after the decimal separator (<strong>0.2</strong>). Note that format verbs always begin with the sign <strong>%.</strong> Here are some useful format verbs :</p>
<ul>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">%s</code></span> for printing a string.</p>
<ul>
<li>Ex : <span v-highlightjs><code class="go" v-pre style="display: inline">fmt.Printf("Hotel: %s", hotelName)</code></span></li>
</ul></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">%d</code></span> for printing an integer in base 10.</p>
<ul>
<li>Ex : <span v-highlightjs><code class="go" v-pre style="display: inline">fmt.Printf("Number of rooms: %d", totalRooms)</code></span></li>
</ul></li>
</ul>
<p>If you want to print a percentage sign, the syntax is <span v-highlightjs><code class="go" v-pre style="display: inline">%%</code></span>. Note also that to generate a <strong>line break</strong> added a <span v-highlightjs><code class="go" v-pre style="display: inline">\n</code></span> at the end of our format specifier.</p>
<div id="the-for-loop" class="anchor"></div>
<h3 data-number="14.3.9"><span class="header-section-number">14.3.9</span> The for loop <a href="#the-for-loop"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>When roomsAvailable is greater than 0, the program will execute a for loop statement :</p>
<pre v-highlightjs><code class="go" v-pre >for i := 0; roomsAvailable &gt; i; i++ {
   roomNumber := firstRoomNumber + i
   size := rand.Intn(6) + 1
   nights := rand.Intn(10) + 1
   fmt.Println(roomNumber, &quot;:&quot;, size, &quot;people /&quot;, nights, &quot; nights &quot;)
}</code></pre>
<ul>
<li><p>The <strong>init statement</strong> will initialize i to 0</p></li>
<li><p>The <strong>condition</strong> is <span v-highlightjs><code class="go" v-pre style="display: inline">roomsAvailable &gt; i</code></span>. The loop will stop running when this condition will become false. When the value of i will become equal to <span v-highlightjs><code class="go" v-pre style="display: inline">roomsAvailable</code></span> (or greater)</p></li>
<li><p>The <strong>post statement</strong> is <span v-highlightjs><code class="go" v-pre style="display: inline">i++</code></span>. It’s equivalent to <span v-highlightjs><code class="go" v-pre style="display: inline">i = i + 1</code></span>. The increment i at the end of each.</p></li>
</ul>
<p>How many iterations will be executed?</p>
<p>Let’s take an example:</p>
<ul>
<li><p><strong>If they are three rooms available</strong>, the body of the for loop (between the curly braces) will be executed 3 times. i will be equal to 0, 1, and 2.</p>
<ul>
<li><p>First loop:</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">i</code></span> initialized to 0</p>
<ul>
<li>Three is greater than 0 (<span v-highlightjs><code class="go" v-pre style="display: inline">i</code></span>) =&gt; we can launch the execution of the body</li>
</ul>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">i</code></span> is incremented (post statement). The new value of i is 1</p></li>
<li><p>Second loop:</p>
<ul>
<li>Three is greater than 1 (<span v-highlightjs><code class="go" v-pre style="display: inline">i</code></span>) =&gt; we can launch the execution of the body =&gt; we can launch the execution of the body</li>
</ul>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">i</code></span> is incremented (post statement). The new value of i is 2</p></li>
<li><p>Third loop</p>
<ul>
<li>Three is greater than 2 (<span v-highlightjs><code class="go" v-pre style="display: inline">i</code></span>) =&gt; we can launch the execution of the body =&gt; we can launch the execution of the body</li>
</ul>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">i</code></span> is incremented (post statement). The new value of i is 3</p></li>
<li><p>Fourth loop</p>
<ul>
<li>Three is not greater than three =&gt; we stop.</li>
</ul></li>
</ul></li>
<li><p><strong>When four rooms are available</strong> the code is executed four times.</p>
<ul>
<li>Try to detail the loops by yourself to prove this.</li>
</ul></li>
</ul>
<p>Now, let’s take the general case: the number of rooms available is stored into the variable <span v-highlightjs><code class="go" v-pre style="display: inline">roomsAvailable</code></span>, hence the number of iterations is equal to the value of <span v-highlightjs><code class="go" v-pre style="display: inline">roomsAvailable</code></span>.</p>
<div id="the-for-loop-body" class="anchor"></div>
<h3 data-number="14.3.10"><span class="header-section-number">14.3.10</span> The for loop body <a href="#the-for-loop-body"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>Inside the for loop body the program :</p>
<ul>
<li><p>We add the value of <span v-highlightjs><code class="go" v-pre style="display: inline">i</code></span> to <span v-highlightjs><code class="go" v-pre style="display: inline">firstRoomNumber</code></span> to generate the room number (110, 111, 112, ...). The result of the addition is stored into the variable <span v-highlightjs><code class="go" v-pre style="display: inline">roomNumber</code></span>.</p></li>
<li><p>We generate a random value for <span v-highlightjs><code class="go" v-pre style="display: inline">size</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">nights</code></span>.</p>
<ul>
<li>Note that we add 1 to the random value generated. That’s because rand.Intn(10) will generate a random number between 0 included and 10 excluded : (possible values are : 0,1,2,3,4,5,6,7,8,9). If we want to have a number between 1 and 10, we need to add 1 to it.</li>
</ul></li>
<li><p>We then print the value as expected by the specification :</p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">fmt.Println(roomNumber, ":", size, "people /", nights, " nights ")</code></span></p></li>
</ul>
<p>An alternative is to use Printf with format verbs :</p>
<pre v-highlightjs><code class="go" v-pre >fmt.Printf(&quot;%d: %d people / %d nights\n&quot;, roomNumber, size, nights)</code></pre>
<p>Both are working; the second one is easier to read and more elegant (in my opinion)</p>
<div id="test-yourself" class="anchor"></div>
<h1 data-number="15"><span class="header-section-number">15</span> Test yourself <a href="#test-yourself"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="questions" class="anchor"></div>
<h2 data-number="15.1"><span class="header-section-number">15.1</span> Questions <a href="#questions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>What is a boolean expression?</p></li>
<li><p>Enumerate four boolean operators.</p></li>
<li><p>How many times the word “Go” will be printed on screen with the following programs</p>
<ol type="1">
<li><p>for i := 0; i &lt; 10; i++ { fmt.Println(“Go”) }</p></li>
<li><p>for i := 1; i &lt; 10; i++ { fmt.Println(“Go”) }</p></li>
<li><p>for { fmt.Println(“Go”) }</p></li>
<li><p>for i := 10; i &lt; 10; i++ { fmt.Println(“Go”) }</p></li>
</ol></li>
</ol>
<div id="answers" class="anchor"></div>
<h2 data-number="15.2"><span class="header-section-number">15.2</span> Answers <a href="#answers"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>What is a boolean expression?</p>
<ol type="1">
<li><p>A “boolean expression” is an expression that produces a boolean value when evaluated<a href="#fn1" class="footnote-ref" id="fnref1" role="doc-noteref"><sup>1</sup></a></p></li>
<li><p>An expression is a syntactic entity in a programming language that may be evaluated to determine its value. <b-link class="citation" data-cites="mitchell2003concepts" href="#mitchell2003concepts" >[@mitchell2003concepts]</b-link></p></li>
</ol></li>
<li><p>Enumerate four boolean operators.</p>
<ol type="1">
<li><p>==</p></li>
<li><p>!=</p></li>
<li><p>&lt;</p></li>
<li><p>&gt;</p></li>
<li><p>&lt;=</p></li>
<li><p>&gt;=</p></li>
</ol></li>
<li><p>How many times the word “Go” will be outputed on screen with the following program</p>
<ol type="1">
<li><p>10</p></li>
<li><p>9</p></li>
<li><p>An infinite number (infinite loop)</p></li>
<li><p>0</p></li>
</ol></li>
</ol>
<div id="key-takeaways" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="16"><span class="header-section-number">16</span> Key takeaways <a href="#key-takeaways"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>A boolean is a type that can be equal to <span v-highlightjs><code class="go" v-pre style="display: inline">true</code></span> or <span v-highlightjs><code class="go" v-pre style="display: inline">false</code></span></p>
<ul>
<li>There is no in-between :)</li>
</ul></li>
<li><p>By default, a boolean is <span v-highlightjs><code class="go" v-pre style="display: inline">false</code></span>. (This is the zero values of booleans in Go)</p></li>
<li><p>A boolean expression produces a boolean.</p>
<ul>
<li><p>Ex :</p>
<ul>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">ageJohn &gt; agePaul</code></span></p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">ageJohn == agePaul</code></span></p>
<ul>
<li>Will be evaluated to either <span v-highlightjs><code class="go" v-pre style="display: inline">true</code></span> or <span v-highlightjs><code class="go" v-pre style="display: inline">false</code></span>, depending on the value of <span v-highlightjs><code class="go" v-pre style="display: inline">ageJohn</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">agePaul</code></span></li>
</ul></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">25 &gt; 32</code></span></p>
<ul>
<li>Will be evaluated to <span v-highlightjs><code class="go" v-pre style="display: inline">false</code></span></li>
</ul></li>
</ul></li>
</ul></li>
<li><p>Conditional constructs will use boolean expressions</p></li>
<li><p>The most used conditional constructs are :</p>
<ul>
<li><p>if / else</p></li>
<li><p>if without else</p></li>
<li><p>switch case</p></li>
<li><p>for loops</p></li>
</ul></li>
<li><p><strong>if / else if / else</strong> constructs might be hard to read/understand (maybe use a switch case ?)</p></li>
<li><p>You will often see an “if without else” construct in Go code (usually to check errors)</p></li>
</ul>
<section class="footnotes" role="doc-endnotes">
<hr />
<ol>
<li id="fn1" role="doc-endnote"><p>https://en.wikipedia.org/wiki/Boolean_expression<a href="#fnref1" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
</ol>
</section>

                    <!-- END CONTENT -->
                    <!-- Bibliography -->
                    <h1>Bibliography</h1>
                    <ChapterBibliography chapter-id="Chap9ControlStatements"></ChapterBibliography>
					<!-- Next / Previous -->
					<b-row class="ml-1 mr-1 ">
						
							<b-col class="text-center border mr-1 p-2" >
								<router-link :to="{name:'Chap8VariablesConstantsAndBasicTypes'}">
									<p><u><small>Previous</small></u></p>
									<p><small>Variables, constants and basic types</small></p>
								</router-link>
							</b-col>
						
						
							<b-col class="text-center border p-1 ">
								<router-link :to="{name:'Chap10Functions'}">
									<p><u><small>Next</small></u></p>
									<p><small>Functions</small></p>
								</router-link>
							</b-col>
						
					</b-row>
					<b-row class="mt-1 ml-1 mr-1">
						<b-col class="text-center border p-1 ">
							<b-link :to="{name:'Home'}" >Table of contents</b-link>
						</b-col>
					</b-row>
          			<FeedbackInvite></FeedbackInvite>
					<NewsletterInput></NewsletterInput>
					<Footer></Footer>
                </b-col>
				<b-col ></b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import TOCChapter from "@/components/toc/TocChapter";
import ChapterBibliography from "@/components/ChapterBibliography";
import NavBar from "@/components/NavBar";
import { BIconLink45deg } from 'bootstrap-vue'
import Footer from "@/components/Footer";
import ChapterHeading from "@/components/ChapterHeading";
import BuyCopyInvite from "@/components/BuyCopyInvite";
import NewsletterInput from "@/components/NewsletterInput";
import FeedbackInvite from "@/components/FeedbackInvite";

const title = "Control Statements - Practical Go Lessons"
const description = "We will discover boolean expressions and control statements"

export default {
  name: "Chap9ControlStatements",
  components: {FeedbackInvite,BuyCopyInvite,NewsletterInput,ChapterHeading, ChapterBibliography,TOCChapter,NavBar,BIconLink45deg, Footer},

mounted() {
    const mathElements = window.document.getElementsByClassName("math");
    const macros = [];
    for (var i = 0; i < mathElements.length; i++) {
      const texText = mathElements[i].firstChild;
      if (mathElements[i].tagName === "SPAN") {
 		window.katex.render(texText.data, mathElements[i], {
          displayMode: mathElements[i].classList.contains('display'),
          throwOnError: true,
          macros: macros,
          fleqn: false
        });
      }
    }
  },

  created() {
    window.scrollTo(0,0);
  },data () {return {publicPath: process.env.BASE_URL}},
  metaInfo: {
      title: title,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: description },
        { name: 'robots', content: "index, follow" },
        { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: title},
        { property: 'og:description', content: description },
        { property: 'og:url', content: window.location.href },
        { property: 'og:site_name', content: 'Practical Go Lessons' },
        { property: 'twitter:card', content: "summary_large_image" },
        { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
      ],
      link : [
        { rel : "canonical", href : window.location.href}
      ]

    }
}
</script>

<style scoped>

</style>
